
import { Options, Vue } from "vue-property-decorator";
import api from "@/api/equipmenttest";

@Options({})
export default class extends Vue {
  private active = 0;
  private qzjdetail = { driverNo: "", deviceNo: "", id: "" };
  private quality: any[] = [];
  private show = false;
  private searchForm = {
    dirId: "",
    level: null,
    deviceTime: "",
    towerNo: "",
    pageSize: 10,
    pageNum: 1,
    total: 0,
  };
  private listData: any[] = [];
  private towerDetail = {
    radius: "",
    angle: "",
    height: "",
    percent: "",
    load: "",
    obliqueAngle: "",
    windSpeed: "",
  };

  mounted() {
    this.qzjdetail = this.$store.state.project.qzjdetail;
    this.searchForm.dirId = this.$store.state.project.projectinfoDetail.id;
    this.searchForm.towerNo = this.qzjdetail.deviceNo;
    api
      .listQualificationByNo({
        projectId: this.$store.state.project.projectinfoDetail.id,
        no: this.qzjdetail.driverNo,
      })
      .then((res: any) => {
        if (res.code === 0) {
          this.quality = res.data;
        }
      });
    api.getDetailByIdTower(this.qzjdetail.id).then((res: any) => {
      if (res.code === 0) {
        this.towerDetail.radius = res.data.radius;
        this.towerDetail.angle = res.data.angle;
        this.towerDetail.height = res.data.height;
        this.towerDetail.percent = res.data.percent;
        this.towerDetail.load = res.data.load;
        this.towerDetail.obliqueAngle = res.data.obliqueAngle;
        this.towerDetail.windSpeed = res.data.windSpeed;
      }
    });
    api.pageTowerAlertRecord(this.searchForm).then((res: any) => {
      if (res.code === 0) {
        if (res.data.records.length > 3) {
          res.data.records = res.data.records.splice(0, 3);
          this.listData = res.data.records;
        }
        this.listData = res.data.records;
      }
    });
  }
  rlHistory() {
    this.$router.push({
      path: "/app/qzjrlhistory",
      query: { id: this.qzjdetail.deviceNo },
    });
  }
  morejianceData() {
    this.$router.push({
      path: "/app/qzjjiancedetail",
      query: { id: this.qzjdetail.deviceNo },
    });
  }
  checkZh() {
    this.show = true;
  }
  moreGaojingData() {
    this.$router.push({
      path: "/app/qzjgaojingdetail",
      query: { id: this.qzjdetail.deviceNo },
    });
  }
}
